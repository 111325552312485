import React from 'react';
import FirebaseContext from './FirebaseContext';

import * as app from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";

const prodConfig = {
  apiKey: "AIzaSyBjdI58iTsqcGc-cxZuyiA32LTjLqXV1FQ",
  authDomain: "codecat-game.firebaseapp.com",
  databaseURL: "https://codecat-game.firebaseio.com",
  projectId: "codecat-game",
  storageBucket: "codecat-game.appspot.com",
  messagingSenderId: "772008323047",
  appId: "1:772008323047:web:df5756e2b6f59dd4"
};

const devConfig = {
  apiKey: "AIzaSyBjdI58iTsqcGc-cxZuyiA32LTjLqXV1FQ",
  authDomain: "codecat-game.firebaseapp.com",
  databaseURL: "https://codecat-game.firebaseio.com",
  projectId: "codecat-game",
  storageBucket: "codecat-game.appspot.com",
  messagingSenderId: "772008323047",
  appId: "1:772008323047:web:df5756e2b6f59dd4"
};

const config =
  process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore(app);
  }

  // *** Auth API ***

  createUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  passwordReset = email => this.auth.sendPasswordResetEmail(email);

  passwordUpdate = password =>
    this.auth.currentUser.updatePassword(password);
}

export default Firebase;
export { FirebaseContext };

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);
