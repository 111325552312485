import React, {Component} from 'react';
import FirebaseContext from '../Firebase/FirebaseContext';
import styled from 'styled-components';

const DownloadLabel = styled.span`
  font-weight: bold;
  font-size: 1.2em;
  margin-right: 15px;
`;

const Button = styled.a`
  padding: 3px 10px;
  margin-right: 15px;
  border: 1px solid #8593ff;
  background-color: #b0baff;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  
  &:hover{
    background-color: #3a3e58;
  }
`;

class Home extends Component {
  state = {releases: []};

  fetchReleases(){
     this.props.firebase.db.collection("releases").orderBy("release_date", "desc").limit(10).get().then((querySnapshot) => {
       const data = [];
       querySnapshot.forEach(doc => data.push({id: doc.id, data: doc.data()}));
       this.setState({releases: data});
    });
  }

  componentDidMount() {
    this.fetchReleases();
  }

  render() {
    return (
      <div>
        <h1>Releases</h1>

        {this.state.releases.map(rel => (
          <div>
            <h2>Version {rel.data.version}</h2>
            <div>
              <DownloadLabel>Downloads: </DownloadLabel>
              {rel.data.downloads.windows ? <Button href={rel.data.downloads.windows}>Windows</Button> : null}
              {rel.data.downloads.mac ? <Button href={rel.data.downloads.mac}>MacOS</Button> : null}
              {rel.data.downloads.linux ? <Button href={rel.data.downloads.linux}>Linux</Button> : null}
            </div>
            <div>{rel.data.description}</div>
            {rel.data.changelog.length > 0 ? (
              <div>
                <h3>Change log:</h3>
                <ul>{rel.data.changelog.map(log=>(<li>{log}</li>))}</ul>
              </div>
            ) : null}

          </div>
        ))}

        
      </div>
    );
  }
}


export default () => (
  <FirebaseContext.Consumer>
    {firebase => <Home firebase={firebase} />}
  </FirebaseContext.Consumer>
);